@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Josefin Sans', sans-serif;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  overflow: -moz-scrollbars-none; /* Older Versions Of FireFox */
}

*::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

:root {
  --header-bg: #0f0c19;
  --accent: #4e3aa3;
  --body-fg: #2f2c39;
  --dark-opposite: #fff;
  --light-text-opposite: #000;
  --light-opposite: #000;
  --dark-text-opposite: #fff;
}

body {
  font-family: sans-serif;
  background: #efefef;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header {
  background: var(--header-bg);
  width: 100%;
  text-align: center;
  color: white;
}
.banner {
  max-width: 100%;
}

@media (max-width: 640px) {
  nav ~ .banner {
    padding-top: 2rem;
  }
}

h1 {
  font-weight: 700;
  font-size: 36px;
  margin-top: 10px;
}

nav {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 1rem 0;
  width: 100%;
}

nav img {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
}

main {
  max-width: 1000px;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  flex: 1;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 15px;
  margin-bottom: 30px;
}

select {
  font-size: 16px;
  border-radius: 15px;
  padding: 12px 12px;
  border: none;
  width: 300px;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);
  box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("./assets/downArrow.svg") no-repeat right #fff;
}

option {
  vertical-align: baseline;
  height: 25px;
}

input {
  padding: 12px 16px;
  background: white;
  border-radius: 15px;
  border: none;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);
  box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);
}

input[type=submit] {
  cursor: pointer;
  border-radius: 15px;
}

#dropbtn,
#subdomain,
#value {
  width: 300px;
  border: none;
  outline:none;
  transition: width 0.4s ease-in-out;
}

#dropbtn:active,
#subdomain:active,
#value:active {
  border: none;
  outline:none;
}

#dropbtn:focus,
#subdomain:focus,
#value:focus{
  width: 450px;
}

.margin-top-15px {
  margin-top: 15px;
}

button {
  border-radius: 15px;
  cursor: pointer;
  border: none;
  background: var(--accent);
  color: white;
  padding: 12px 16px;
  font-size: 16px;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);
  box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);
  transition: 450ms ease-in-out;
}

button:hover {
  background: var(--dark-opposite);
  color: var(--light-text-opposite);
}

.btn-submit {
  /*border-radius: 8px;*/
  border: none;
  background: var(--accent);
  color: white;
  padding: 12px 16px;
  font-size: 16px;
  background: #008000;
  transition: 450ms ease-in-out;
}

.btn-submit:hover{
  background: var(--dark-opposite);
  color: var(--light-text-opposite);
}

.btn-red {
  background: #ff0000;
}

.btn-green {
  background: #008000;
}

.btnBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

a {
  color: var(--accent);
}

footer {
  width: 100vw;
  height: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

footer > h3 {
  font-size: 26px;
  margin-left: 150px;
}

footer > .donate {
  justify-self: flex-end;
  background: #4e3aa3;
  color: white;
  font-size: 18px;
  width: 350px;
  height: 90px;
  padding: 10px;
  overflow: hidden;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  padding-left: 30px;
  padding-top: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: column;
}

footer > .donate > .donate-links {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 5px;
  width: 300px;
}

footer > .donate > .donate-links > img {
  cursor: pointer;
}

@media (max-width: 660px) {
  footer > h3 {
    margin-left: 50px;
  }

  footer > .donate {
    margin-bottom: 20px;
    height: 120px;
    width: 250px;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }

  footer > .donate > .donate-links {
    flex-direction: column;
  }
}

@media (max-width: 430px) {
  footer > h3 {
    margin-left: 30px;
    font-size: 22px;
  }

  footer > .donate {
    margin-bottom: 40px;
    height: 120px;
    width: 205px;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }

  footer > .donate > p {
   text-align: center;
   font-size: 18px;
  }

  footer > .donate > .donate-links {
    flex-direction: column;
  }

  footer > .donate > .donate-links > img {
    width: 100px;
    height: 20px;
    
  }

  h1 {
    text-align: center;
  }
}

.subdomainfield {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 700px;
  min-width: 350px;
}

.subdomainfield:focus > span {
  width: 100px;
}

.subdomainfield > input {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.subdomainfield > span {
  display: flex;
  background-color: #f9d5d5;
  padding-left: 7.5px;
  padding-right: 7.5px;
  font-size: 20px;
  height: 37px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: center;
  border: none;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);
  box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);
}